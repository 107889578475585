<template>
  <form class="pb-3">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label class="titlesfont" for="">Comisión:</label>
          <input
            type="number"
            step="0.01"
            pattern="^\d+(?:\.\d{1,2})?$"
            placeholder="$"
            class="form-control"
            v-model.trim="commission"
            required
          />
        </div>
      </div>
    </div>
    <a-button type="primary" @click="updateUser">Guardar</a-button>
  </form>
</template>

<script>
import { reactive, toRefs } from "vue";
import { getRequest } from "../../../router/auth_guard";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const state = reactive({
      commission: store.getters.request?.commission ?? 0.1,
    });
    async function updateUser() {
      await store.dispatch("updateUser", {
        id: store.getters.request.id,
        commission: parseFloat(state.commission),
      });
      router.replace("/admin/super/requests");
    }
    return { ...toRefs(state), updateUser };
  },
  beforeRouteEnter: getRequest,
};
</script>

<style>
</style>